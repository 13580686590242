import React from 'react'
import * as Styles from './AppContent.styles'

export interface AppContentProps {
    children: React.ReactNode
}

export function AppContent(props: AppContentProps) {

    const { children } = props

    return (
        <Styles.Root>
            {children}
        </Styles.Root>
    )
}
