import { useAuth } from 'react-oidc-context'
import { GITLAB_GRAPHQL_URL } from 'src/constants'

export const useQueryGraphql = <TData, TVariables>(
    query: string,
    options?: RequestInit['headers']
): ((variables?: TVariables) => Promise<TData>) => {

    const auth = useAuth()

    return async (variables?: TVariables) => {
        const token = auth.user?.access_token
        const res = await fetch(GITLAB_GRAPHQL_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                ...(options ?? {})
            },
            body: JSON.stringify({
                query,
                variables
            })
        })

        const json = await res.json()

        if (json.errors) {
            throw json.errors
        }

        return json.data
    }
}
