import React from 'react'

export interface AppErrorProps {
    error: unknown
}

export function AppError(props: AppErrorProps) {

    const { error } = props

    return (
        <div><strong>Error:</strong> {JSON.stringify(error)}</div>
    )
}
