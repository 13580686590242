import React from 'react'

import { AppContextProvider } from 'src/packages/app/contexts/AppContextProvider'
import { AppCurrentUserContextProvider } from 'src/packages/app/contexts/AppCurrentUserContextProvider'

import { Routes, Route, Navigate } from 'react-router-dom'
import { About } from 'src/packages/app-about/components/About/About.lazy'
import { Tracker } from 'src/packages/tracker/components/Tracker/Tracker.lazy'
import { AppHeader } from '../AppHeader/AppHeader'
import { AppContent } from '../AppContent/AppContent'
import { AppToaster } from '../AppToaster/AppToaster'

export function App() {
    return (
        <AppContextProvider>
            <AppCurrentUserContextProvider>
                <AppHeader/>
                <AppContent>
                    <React.Suspense fallback={null}>
                        <Routes>
                            <Route
                                path="about/*"
                                element={(
                                    <About/>
                                )}
                            />
                            <Route
                                path="tracker/*"
                                element={(
                                    <Tracker/>
                                )}
                            />
                            <Route
                                path="*"
                                element={(
                                    <Navigate to="tracker" replace/>
                                )}
                            />
                        </Routes>
                    </React.Suspense>
                </AppContent>
                <AppToaster/>
            </AppCurrentUserContextProvider>
        </AppContextProvider>
    )
}
