/* eslint-disable */
import * as Types from '../../../../types/graphql';

import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions, QueryFunctionContext } from 'react-query';
import { useQueryGraphql } from 'src/hooks/useQueryGraphql';
export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser?: { __typename?: 'UserCore', id: string, name: string, username: string, avatarUrl?: string | null, webUrl: string } | null };


export const CurrentUserDocument = `
    query CurrentUser {
  currentUser {
    id
    name
    username
    avatarUrl
    webUrl
  }
}
    `;
export const useCurrentUserQuery = <
      TData = CurrentUserQuery,
      TError = unknown
    >(
      variables?: CurrentUserQueryVariables,
      options?: UseQueryOptions<CurrentUserQuery, TError, TData>
    ) =>
    useQuery<CurrentUserQuery, TError, TData>(
      variables === undefined ? ['CurrentUser'] : ['CurrentUser', variables],
      useQueryGraphql<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument).bind(null, variables),
      options
    );

useCurrentUserQuery.getKey = (variables?: CurrentUserQueryVariables) => variables === undefined ? ['CurrentUser'] : ['CurrentUser', variables];
;

export const useInfiniteCurrentUserQuery = <
      TData = CurrentUserQuery,
      TError = unknown
    >(
      variables?: CurrentUserQueryVariables,
      options?: UseInfiniteQueryOptions<CurrentUserQuery, TError, TData>
    ) =>{
    const query = useQueryGraphql<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument)
    return useInfiniteQuery<CurrentUserQuery, TError, TData>(
      variables === undefined ? ['CurrentUser.infinite'] : ['CurrentUser.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};


useInfiniteCurrentUserQuery.getKey = (variables?: CurrentUserQueryVariables) => variables === undefined ? ['CurrentUser.infinite'] : ['CurrentUser.infinite', variables];
;
