import React from 'react'

import { AppContext } from 'src/packages/app/contexts/AppContextProvider'

import * as Styles from './AppToaster.styles'
import { ToastProvider } from '@radix-ui/react-toast'

export function AppToaster() {

    const { messages, hideMessage } = React.useContext(AppContext)

    return (
        <ToastProvider>

            {messages.map((message, i) => (
                <Styles.Toast
                    key={i}
                    open
                    onOpenChange={() => hideMessage(message)}
                >
                    {message.text}
                </Styles.Toast>
            ))}

            <Styles.Viewport/>

        </ToastProvider>
    )
}
