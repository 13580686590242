import React from 'react'

export interface AppLoadingProps {

}

export function AppLoading(props: AppLoadingProps) {

    return (
        <div>...</div>
    )
}
