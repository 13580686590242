import { styled } from 'src/stitches'

export const Root = styled('div', {
    all: 'unset',

    variants: {

        variant: {
            'h3': {
                lineHeight: '48px',
                fontSize: '40px',
                fontWeight: 500,
            },
            'h6': {
                lineHeight: '32px',
                fontSize: '20px',
                fontWeight: 500,
            },
        },

        ellipsis: {
            true: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        }

    }
})
