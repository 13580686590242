import { styled } from 'src/stitches'
import { keyframes } from '@stitches/react'
import * as DialogPrimitive from '@radix-ui/react-dialog'

const overlayIn = keyframes({
    '0%': {
        opacity: 0,
    },
    '100%': {
        opacity: 1,
    },
})

const contentIn = keyframes({
    '0%': {
        opacity: 0,
        transform: 'translateX(-100%)',
    },
    '100%': {
        opacity: 1,
        transform: 'translateX(0%)',
    },
})

export const Root = DialogPrimitive.Root
export const Portal = DialogPrimitive.Portal

export const Container = styled('div', {
    position: 'fixed',
    zIndex: 1000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
})

export const Overlay = styled(DialogPrimitive.Overlay, {
    position: 'fixed',
    zIndex: -1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0 0 0 / 0.5)',

    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayIn} 200ms ease-out forwards`,
    }
})

export const Content = styled(DialogPrimitive.Content, {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    flexFlow: 'column',
    minWidth: 280,
    background: '#fff',
    outline: 'none',

    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${contentIn} 200ms ease-out forwards`,
    }
})
