import { styled } from 'src/stitches'
import * as ToastPrimitive from '@radix-ui/react-toast'

export const Toast = styled(ToastPrimitive.Toast, {
    display: 'grid',
    alignItems: 'center',
    padding: '$2',
    backgroundColor: '$white',
    borderRadius: 6,
    boxShadow: '$boxSoft16',
})

export const Viewport = styled(ToastPrimitive.Viewport, {
    listStyle: 'none',
    position: 'fixed',
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '$1',
    margin: 0,
    padding: '$2',
    width: 320,
    maxWidth: '100vw',
    zIndex: '$toast',
    outline: 'none',
})
