import React from 'react'

import { useAuth } from 'react-oidc-context'

import { AppLoading } from '../components/AppLoading/AppLoading'
import { AppError } from '../components/AppError/AppError'
import { AppAuth } from 'src/packages/app/components/AppAuth/AppAuth'

export interface AppContextValue {
    messages: AppContextValueMessage[]
    showMessage: (message: AppContextValueMessage) => void
    hideMessage: (message: AppContextValueMessage) => void
    exit: () => void
}

export interface AppContextValueMessage {
    text: string
}

export const AppContext = React.createContext<AppContextValue>({} as AppContextValue)

export interface AppContextProviderProps {
    children: React.ReactNode
}

export function AppContextProvider(props: AppContextProviderProps) {

    const { children } = props

    const auth = useAuth()

    const [ messages, setMessages ] = React.useState<AppContextValueMessage[]>([])

    const showMessage = React.useCallback((message: AppContextValueMessage) => {
        setMessages((messages) => ([
            ...messages,
            message
        ]))
    }, [])

    const hideMessage = React.useCallback((message: AppContextValueMessage) => {
        setMessages((messages) => messages.filter(existsMessage => existsMessage !== message))
    }, [])

    const exit = React.useCallback(() => {
        auth.removeUser().then(() => window.location.reload())
    }, [auth])

    const value = React.useMemo(() => {
        return {
            messages,
            showMessage,
            hideMessage,
            exit,
        }
    }, [
        messages,
        showMessage,
        hideMessage,
        exit,
    ])

    if (auth.isLoading || auth.activeNavigator === 'signinSilent' || auth.activeNavigator === 'signoutRedirect') {
        return (
            <AppLoading/>
        )
    }

    if (auth.error) {
        return (
            <AppError
                error={auth.error}
            />
        )
    }

    if (!auth.isAuthenticated) {
        return (
            <AppAuth
                onEnter={() => void auth.signinRedirect()}
            />
        )
    }

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
}
