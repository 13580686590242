import { styled } from 'src/stitches'

export const Root = styled('button', {
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 40,
    minHeight: 40,
    padding: 0,
    lineHeight: 1,
    borderRadius: '50%',
    color: '$gray10',
    cursor: 'pointer',

    '&:hover': {
        color: '$gray12',
        backgroundColor: '$blackA3',
    },
    '&:active': {
        backgroundColor: '$blackA6',
    },
    '&:focus-visible': {
        boxShadow: `0 0 0 2px black`
    },

    variants: {

        size: {
            'sm': {
                width: 32,
                height: 32,
            },
            'lg': {
                width: 48,
                height: 48,
            },
        },

        edge: {
            'start': {
                marginLeft: 'calc(0px - $1)',
            },
            'end': {
                marginRight: 'calc(0px - $1)',
            },
        },

    }
})
