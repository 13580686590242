import { styled } from 'src/stitches'
import * as AvatarPrimitive from '@radix-ui/react-avatar'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'

export const Avatar = styled(AvatarPrimitive.Root, {
    flexShrink: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
    overflow: 'hidden',
    userSelect: 'none',
    width: 40,
    height: 40,
    borderRadius: '100%',
})

export const AvatarImage = styled(AvatarPrimitive.Image, {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 'inherit',
})

export const Menu = DropdownMenuPrimitive.Root

export const MenuTrigger = styled(DropdownMenuPrimitive.Trigger, {
    cursor: 'pointer'
})

export const MenuContent = styled(DropdownMenuPrimitive.Content, {
    minWidth: 120,
    backgroundColor: '$white',
    borderRadius: '$1',
    padding: 'calc($1 / 2)',
    boxShadow: '$boxSoft16',
})

export const MenuItem = styled(DropdownMenuPrimitive.Item, {
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: 32,
    padding: '0 $2',
    lineHeight: 1,
    fontSize: 14,
    borderRadius: 'calc($1 / 2)',
    userSelect: 'none',
    cursor: 'pointer',

    variants: {

        color: {
            primary: {
                color: '$black',
                '&:focus': {
                    backgroundColor: '$primary',
                    color: '$primary1',
                },
            },
            failure: {
                color: '$failure11',
                '&:focus': {
                    backgroundColor: '$failure',
                    color: '$failure1',
                },
            },
        },

    },
    defaultVariants: {

        color: 'primary'

    }
})

export const MenuSeparator = styled(DropdownMenuPrimitive.Separator, {
    height: 1,
    backgroundColor: '$separator',
    margin: 'calc($1 / 2) 0',
})

export const MenuArrow = styled(DropdownMenuPrimitive.Arrow, {
    fill: 'white',
})
