import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import './index.css'

import { OidcContextProvider } from 'src/contexts/OidcContextProvider'
import { QueryContextProvider } from 'src/contexts/QueryContextProvider'
import { App } from './packages/app/components/App/App'

const element = (
    <React.StrictMode>
        <BrowserRouter>
            <OidcContextProvider>
                <QueryContextProvider>
                    <App/>
                </QueryContextProvider>
            </OidcContextProvider>
        </BrowserRouter>
    </React.StrictMode>
)

ReactDOM.render(
    element,
    document.getElementById('root')
)
